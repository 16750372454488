import loadable from "@loadable/component";
import { Outlet, ScrollRestoration, useSearchParams } from "react-router-dom";
import Header from "../header";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { setCookie } from "react-use-cookie";

const TrackPageView = loadable(() => import("../track-page-view"));

const Footer = loadable(() => import("../footer"));

export default function RootLayout(): JSX.Element {
  const [searchParams] = useSearchParams();

  const captureUTMs = () => {
    const params = new URLSearchParams(window.location.search);
    const utmParams = {
      utm_source: params.get("utm_source"),
      utm_medium: params.get("utm_medium"),
      utm_campaign: params.get("utm_campaign"),
      utm_content: params.get("utm_content"),
      utm_term: params.get("utm_term"),
    };

    // Store UTM parameters in localStorage (or cookies)
    localStorage.setItem("utm_params", JSON.stringify(utmParams));
  };

  const saveGclid = (gclid: string) => {
    setCookie("gclid", gclid, {
      domain: import.meta.env.VITE_BASE_DOMAIN,
      Secure: import.meta.env.MODE !== "development",
    });
  };

  const saveCampaign = (campaignCode: string) => {
    setCookie("campaign", campaignCode, {
      domain: import.meta.env.VITE_BASE_DOMAIN,
      Secure: import.meta.env.MODE !== "development",
    });
  };

  useEffect(() => {
    const campaignCode = searchParams.get("c");
    if (campaignCode) {
      saveCampaign(campaignCode);
    }

    const gclid = searchParams.get("gclid");
    if (gclid) {
      saveGclid(gclid);
    }

    captureUTMs();
  }, [searchParams]);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: import.meta.env.VITE_GTM_TRACKING_ID!,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <div className="bg-background h-full">
      <div className="relative min-h-screen">
        <Header />
        <Outlet />
        <ScrollRestoration />
      </div>
      <Footer />
      <TrackPageView />
    </div>
  );
}
